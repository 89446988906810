<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="mb-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('admin.table.settings.search') + '...'"
              />
              <b-button
                v-if="$ability.can('store', 'attributes')"
                variant="primary"
                @click="openEditor(0)"
              >
                <span class="text-nowrap">{{ $t('general.add') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('admin.table.settings.empty_text')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(enabled_for_filter)="data">
          <feather-icon
            :icon="data.item.enabled_for_filter ? 'CheckCircleIcon' : 'CircleIcon'"
            size="16"
            class="align-middle"
          />
        </template>

        <template #cell(actions)="data">
          <span
            v-if="$ability.can('update', 'attributes')"
            class="btn btn-primary btn-sm mr-1"
            @click="openEditor(data.item.id)"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
              class="align-middle"
            />
          </span>
          <span
            v-if="$ability.can('destroy', 'attributes')"
            class="btn btn-danger btn-sm"
            @click="destroy(data.item.id)"
          >
            <feather-icon
              icon="TrashIcon"
              size="16"
              class="align-middle"
            />
          </span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('admin.table.settings.showing') }} {{ dataMeta.from }} {{ $t('admin.table.settings.to') }} {{ dataMeta.to }} {{ $t('admin.table.settings.of') }} {{ dataMeta.of }} {{ $t('admin.table.settings.entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <value-form
        :opened-value-form.sync="openedValueForm"
        :attribute-id="attributeId"
        :value-id="valueId"
        :for-page="forPage"
        :languages="languages"
        @refetch-data="refetchData"
      />
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ValueForm from '@/views/catalog/attributes/values/ValueForm'
import GlobalMixin from '@mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import useList from './useList'
import storeModule from './storeModule'

export default {
  components: {
    ValueForm,
  },
  mixins: [GlobalMixin],
  props: {
    forPage: {
      type: [Boolean],
      default: false,
    },
    attributeId: {
      type: [Number, null],
      default: null,
    },
    languages: {
      type: [Array],
      default: null,
    },
  },
  data() {
    return {
      openedValueForm: false,
      valueId: 0,
      tableColumns: [
        { key: 'title', label: this.$t('admin.table.fields.title'), sortable: false },
        { key: 'slug', label: this.$t('form.slug.label'), sortable: false },
        { key: 'position', label: this.$t('form.position.label'), sortable: false },
        { key: 'actions', label: this.$t('admin.table.fields.actions') },
      ],
    }
  },
  beforeMount() {
    this.idFilter = this.attributeId
  },
  methods: {
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`/api/attributes/${this.attributeId}/values/${id}`)
          .then(() => this.refetchData())
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
    openEditor(valueId) {
      this.valueId = valueId
      this.openedValueForm = true
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'attribute_values'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      idFilter,
    } = useList()

    return {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      idFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
