<template>
  <component :is="item === undefined ? 'div' : 'div'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-card>
          <b-form>
            <b-tabs pills>
              <b-tab :title="$t('admin.products.tabs.general.label')">
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.slug.label')"
                      label-for="slug"
                    >
                      <b-form-input
                        id="slug"
                        v-model="item.slug"
                        :state="errors && errors.slug ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.slug">
                        {{ errors.slug[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.position.label')"
                      label-for="position"
                    >
                      <b-form-input
                        id="position"
                        v-model="item.position"
                        :state="errors && errors.position ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.position">
                        {{ errors.position[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.status.label')"
                      label-for="status"
                      :state="errors && errors.status ? false : null"
                    >
                      <v-select
                        id="status"
                        v-model="item.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="status"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.status">
                        {{ errors.status[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.type.label')"
                      label-for="type"
                      :state="errors && errors.type ? false : null"
                    >
                      <v-select
                        id="type"
                        v-model="item.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="typeOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="type"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.type">
                        {{ errors.type[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="item.type !== 'slider'"
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.logical.label')"
                      label-for="logical"
                      :state="errors && errors.logical ? false : null"
                    >
                      <v-select
                        id="logical"
                        v-model="item.logical"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="logicalOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="logical"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.logical">
                        {{ errors.logical[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.unit.label')"
                      label-for="position"
                    >
                      <b-form-input
                        id="unit"
                        v-model="item.unit"
                        :state="errors && errors.unit ? false : null"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.enabled_for_filter.label')"
                      label-for="enabled_for_filter"
                    >
                      <b-form-checkbox
                        id="enabled_for_filter"
                        v-model="item.enabled_for_filter"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.enabled_for_filter">
                        {{ errors.enabled_for_filter[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.enabled_for_list.label')"
                      label-for="enabled_for_list"
                    >
                      <b-form-checkbox
                        id="enabled_for_list"
                        v-model="item.enabled_for_list"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.enabled_for_list">
                        {{ errors.enabled_for_list[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.enabled_for_page.label')"
                      label-for="enabled_for_page"
                    >
                      <b-form-checkbox
                        id="enabled_for_page"
                        v-model="item.enabled_for_page"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.enabled_for_page">
                        {{ errors.enabled_for_page[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="item.enabled_for_page"
                    cols="12"
                    md="4"
                  >
                    <!-- media -->
                    <b-media no-body>
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->

                        <!-- reset -->
                        <b-button
                          v-if="item.avatar"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.avatar = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                        <!--/ reset -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="avatar"
                          type="attribute_avatar"
                          :multiple="false"
                          @fm-selected="selectedAvatar"
                        />

                        <b-row
                          v-if="errors && errors.avatar"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors.avatar[0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="errors && errors['avatar.type']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['avatar.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                      </b-media-body>
                    </b-media>
                    <!--/ media -->
                  </b-col>
                </b-row>
                <b-tabs pills>
                  <b-tab
                    v-for="language in languages"
                    :key="language.code"
                  >
                    <template #title>
                      <b-img
                        :src="require('@/assets/images/flags/' + language.code + '.svg')"
                        height="16"
                        width="16"
                        class="mr-1"
                      />
                      <span class="d-none d-sm-inline">{{ language.title }}</span>
                    </template>
                    <b-row>
                      <b-col
                        cols="24"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.title.label')"
                          :label-for="'title.' + language.code"
                        >
                          <b-form-input
                            :id="'title.' + language.code"
                            v-model="item.title[language.code]"
                            :state="errors && errors['title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                            {{ errors['title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <template v-if="item.enabled_for_page">
                        <b-col
                          cols="24"
                          md="12"
                        >
                          <b-form-group
                            :label="$t('form.description.label')"
                            :label-for="'description.' + language.code"
                            :state="errors && errors['description.' + language.code] ? false : null"
                          >
                            <b-form-textarea
                              :id="'description.' + language.code"
                              v-model="item.description[language.code]"
                              :state="errors && errors['description.' + language.code] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                              {{ errors['description.' + language.code][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="24"
                          md="12"
                        >
                          <b-form-group
                            :label="$t('form.meta_h1.label')"
                            :label-for="'meta_h1.' + language.code"
                          >
                            <b-form-input
                              :id="'meta_h1.' + language.code"
                              v-model="item.meta_h1[language.code]"
                              :state="errors && errors['meta_h1.' + language.code] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors && errors['meta_h1.' + language.code]">
                              {{ errors['meta_h1.' + language.code][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="24"
                          md="12"
                        >
                          <b-form-group
                            :label="$t('form.meta_title.label')"
                            :label-for="'meta_title.' + language.code"
                          >
                            <b-form-input
                              :id="'meta_title.' + language.code"
                              v-model="item.meta_title[language.code]"
                              :state="errors && errors['meta_title.' + language.code] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                              {{ errors['meta_title.' + language.code][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="24"
                          md="12"
                        >
                          <b-form-group
                            :label="$t('form.meta_description.label')"
                            :label-for="'meta_description.' + language.code"
                          >
                            <b-form-textarea
                              :id="'meta_description.' + language.code"
                              v-model="item.meta_description[language.code]"
                              :state="errors && errors['meta_description.' + language.code] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                              {{ errors['meta_description.' + language.code][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="24"
                          md="12"
                        >
                          <b-form-group
                            :label="$t('form.meta_keywords.label')"
                            :label-for="'meta_keywords.' + language.code"
                          >
                            <b-form-textarea
                              :id="'meta_keywords.' + language.code"
                              v-model="item.meta_keywords[language.code]"
                              :state="errors && errors['meta_keywords.' + language.code] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors && errors['meta_keywords.' + language.code]">
                              {{ errors['meta_keywords.' + language.code][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="24"
                          md="12"
                        >
                          <b-form-group
                            :label="$t('form.canonical.label')"
                            :label-for="'canonical.' + language.code"
                          >
                            <b-form-input
                              :id="'canonical.' + language.code"
                              v-model="item.canonical[language.code]"
                              :state="errors && errors['canonical.' + language.code] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                              {{ errors['canonical.' + language.code][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </template>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-tab>
              <b-tab
                v-if="item.enabled_for_page"
                :title="$t('admin.navigation.extensions')"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      :label="$t('form.addExtension.label')"
                      label-for="addExtensionId"
                    >
                      <v-select
                        id="addExtensionId"
                        v-model="addExtensionId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="extensionOptions"
                        :reduce="val => val.value"
                        input-id="addExtensionId"
                        :searchable="true"
                        @search="searchExtension"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-button
                      variant="primary"
                      class="mt-2"
                      block
                      @click="addExtension"
                    >
                      {{ $t('general.add') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <div class="halo-tree mb-2">
                      <draggable
                        v-model="item.extensions"
                        group="tree"
                      >
                        <div
                          v-for="(extension, index) in item.extensions"
                          :key="extension.value"
                          class="li"
                        >
                          <div class="tree-node-el">
                            <div>
                              <span>{{ extension.label }}</span>
                            </div>
                            <div>
                              <button
                                class="btn btn-danger btn-sm"
                                @click="removeExtension(index)"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                  size="14"
                                  class-name="mr-50"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </draggable>
                    </div>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
            <b-row>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="onSubmit"
            >
              {{ $t('general.save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :to="{ name: 'attributes-index' }"
            >
              {{ $t('general.cancel') }}
            </b-button>
          </b-form>
        </b-card>

        <b-card>
          <List
            :attribute-id="item.id"
            :for-page="item.enabled_for_page"
            :languages="languages"
          />
        </b-card>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import EditorMixin from '@/mixins/EditorMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import List from './values/List'

export default {
  components: {
    List,
  },
  mixins: [GlobalMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      typeOptions: [
        { label: this.$t('general.attribute_types.checkbox'), value: 'checkbox' },
        { label: this.$t('general.attribute_types.slider'), value: 'slider' },
      ],
      logicalOptions: [
        { label: this.$t('general.attribute_logical.and'), value: 'and' },
        { label: this.$t('general.attribute_logical.or'), value: 'or' },
      ],
      addExtensionId: null,
      extensionOptions: [],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get('/api/admin/extensions', {
      params: {
        per_page: 500,
      },
    })
      .then(response => {
        this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http.get(`/api/attributes/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'attributes-index' })
        }
      })
  },
  methods: {
    selectedAvatar(data) {
      this.item.avatar = data
    },
    async searchExtension(query) {
      await this.$http.get('/api/admin/extensions', {
        params: {
          query,
          per_page: 500,
        },
      })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    addExtension() {
      this.item.extensions.push(_.find(this.extensionOptions, { value: this.addExtensionId }))
      this.addExtensionId = null
    },
    removeExtension(index) {
      this.item.extensions.splice(index, 1)
    },
    onSubmit() {
      const data = {
        position: this.item.position,
        slug: this.item.slug,
        status: this.item.status,
        type: this.item.type,
        logical: this.item.type !== 'slider' ? this.item.logical : 'and',
        unit: this.item.unit,
        make_type: 'attribute',
        enabled_for_filter: this.item.enabled_for_filter,
        enabled_for_list: this.item.enabled_for_list,
        enabled_for_page: this.item.enabled_for_page,
        avatar: this.item.avatar,
        title: this.item.title,
        description: this.item.description,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        extensions: _.map(this.item.extensions, ext => ext.value),
      }

      this.$http.put(`/api/attributes/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'attributes-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        position: item.position,
        status: item.status,
        type: item.type,
        logical: item.logical,
        unit: item.unit,
        slug: item.slug,
        enabled_for_filter: item.enabled_for_filter,
        enabled_for_list: item.enabled_for_list,
        enabled_for_page: item.enabled_for_page,
        avatar: this.$options.filters.singleMedia(item.avatar),
        title: {},
        description: {},
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        canonical: {},
        extensions: this.$options.filters.transformForVSelect(item.extensions, 'id', 'title'),
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
        data.meta_description[language.code] = translation ? translation.meta_description : null
        data.meta_h1[language.code] = translation ? translation.meta_h1 : null
        data.meta_keywords[language.code] = translation ? translation.meta_keywords : null
        data.meta_title[language.code] = translation ? translation.meta_title : null
        data.canonical[language.code] = translation ? translation.canonical : null
      })

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
