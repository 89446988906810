<template>
  <b-sidebar
    id="opened-value-form"
    :visible="openedValueForm"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-value-form', val)"
    @shown="loadAttr()"
  >
    <template>
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('form.value.label') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-card
        v-if="item"
        class="card-value"
      >
        <b-tabs pills>
          <b-tab :title="$t('admin.products.tabs.general.label')">
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="$t('form.slug.label')"
                  :label-for="'item.slug'"
                >
                  <b-form-input
                    :id="'item.slug'"
                    v-model="item.slug"
                    :state="errors && errors.slug ? false : null"
                  />
                  <b-form-invalid-feedback v-if="errors && errors.slug">
                    {{ errors.slug[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="$t('form.position.label')"
                  :label-for="'item.position'"
                >
                  <b-form-input
                    :id="'item.position'"
                    v-model="item.position"
                    type="number"
                    step="1"
                    min="0"
                    :state="errors && errors.position ? false : null"
                  />
                  <b-form-invalid-feedback v-if="errors && errors.position">
                    {{ errors.position[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        rounded
                        :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                        height="80"
                      />
                    </b-link>
                    <!--/ avatar -->

                    <!-- reset -->
                    <b-button
                      v-if="item.avatar"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="item.avatar = null"
                    >
                      {{ $t('general.reset') }}
                    </b-button>
                    <!--/ reset -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <InitFileManager
                      field="avatar"
                      type="attribute_avatar"
                      :multiple="false"
                      @fm-selected="selectedAvatar"
                    />

                    <b-row
                      v-if="errors && errors.avatar"
                    >
                      <b-col cols="12">
                        <b-form-invalid-feedback :state="false">
                          {{ errors.avatar[0] }}
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="errors && errors['avatar.type']"
                    >
                      <b-col cols="12">
                        <b-form-invalid-feedback :state="false">
                          {{ errors['avatar.type'][0] }}
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>

                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-col>
            </b-row>
            <b-tabs>
              <b-tab
                v-for="language in languages"
                :key="language.code"
              >
                <template #title>
                  <b-img
                    :src="require('@/assets/images/flags/' + language.code + '.svg')"
                    height="16"
                    width="16"
                    class="mr-1"
                  />
                  <span class="d-none d-sm-inline">{{ language.title }}</span>
                </template>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('form.title.label')"
                      :label-for="'item.title.' + language.code"
                    >
                      <b-form-input
                        :id="'item.title.' + language.code"
                        v-model="item.title[language.code]"
                        :state="errors && errors.title && errors.title[language.code] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['item.title.' + language.code]">
                        {{ errors.title[language.code][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('form.description.label')"
                      :label-for="'item.description.' + language.code"
                    >
                      <b-form-input
                        :id="'item.description.' + language.code"
                        v-model="item.description[language.code]"
                        :state="errors && errors.description && errors.description[language.code] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['item.description.' + language.code]">
                        {{ errors.description[language.code][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <template v-if="forPage">
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_h1.label')"
                        :label-for="'meta_h1.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_h1.' + language.code"
                          v-model="item.meta_h1[language.code]"
                          :state="errors && errors['meta_h1.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_h1.' + language.code]">
                          {{ errors['meta_h1.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_title.label')"
                        :label-for="'meta_title.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_title.' + language.code"
                          v-model="item.meta_title[language.code]"
                          :state="errors && errors['meta_title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                          {{ errors['meta_title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_description.label')"
                        :label-for="'meta_description.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_description.' + language.code"
                          v-model="item.meta_description[language.code]"
                          :state="errors && errors['meta_description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                          {{ errors['meta_description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_keywords.label')"
                        :label-for="'meta_keywords.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_keywords.' + language.code"
                          v-model="item.meta_keywords[language.code]"
                          :state="errors && errors['meta_keywords.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_keywords.' + language.code]">
                          {{ errors['meta_keywords.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.canonical.label')"
                        :label-for="'canonical.' + language.code"
                      >
                        <b-form-input
                          :id="'canonical.' + language.code"
                          v-model="item.canonical[language.code]"
                          :state="errors && errors['canonical.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                          {{ errors['canonical.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </template>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-tab>
          <b-tab
            v-if="forPage"
            :title="$t('admin.navigation.extensions')"
          >
            <b-row>
              <b-col
                cols="12"
                md="8"
              >
                <b-form-group
                  :label="$t('form.addExtension.label')"
                  label-for="addExtensionId"
                >
                  <v-select
                    id="addExtensionId"
                    v-model="addExtensionId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="extensionOptions"
                    :reduce="val => val.value"
                    input-id="addExtensionId"
                    :searchable="true"
                    @search="searchExtension"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-button
                  variant="primary"
                  class="mt-2"
                  block
                  @click="addExtension"
                >
                  {{ $t('general.add') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <div class="halo-tree mb-2">
                  <draggable
                    v-model="item.extensions"
                    group="tree"
                  >
                    <div
                      v-for="(extension, index) in item.extensions"
                      :key="extension.value"
                      class="li"
                    >
                      <div class="tree-node-el">
                        <div>
                          <span>{{ extension.label }}</span>
                        </div>
                        <div>
                          <button
                            class="btn btn-danger btn-sm"
                            @click="removeExtension(index)"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              size="14"
                              class-name="mr-50"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

        <b-row>
          <b-col cols="12">
            <b-button
              variant="primary"
              block
              @click="submit"
            >
              {{ $t('general.save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>

    </template>
  </b-sidebar>
</template>

<script>
import _ from 'lodash'
import GlobalMixin from '@mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin],
  model: {
    prop: 'openedValueForm',
    event: 'update:opened-value-form',
  },
  props: {
    openedValueForm: {
      type: Boolean,
      required: true,
    },
    attributeId: {
      type: Number,
      required: true,
    },
    valueId: {
      type: Number,
      required: true,
    },
    forPage: {
      type: [Boolean],
      default: false,
    },
    languages: {
      type: [Array],
      default: null,
    },
  },
  data() {
    return {
      item: null,
      addExtensionId: null,
      extensionOptions: [],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/admin/extensions', {
      params: {
        per_page: 500,
      },
    })
      .then(response => {
        this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })
  },
  methods: {
    async loadAttr() {
      if (this.valueId) {
        await this.$http.get(`/api/attributes/${this.attributeId}/values/${this.valueId}`)
          .then(response => {
            const { data } = response.data
            this.item = this.transformData(data)
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.item = undefined
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('notifications.not_found'),
                  icon: 'AlertCircleIcon',
                  variant: 'warning',
                },
              })
            }
          })
      } else {
        this.item = this.transformData(null)
      }
    },
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-value-form', false)
      this.item = null
    },
    selectedAvatar(data) {
      this.item.avatar = data
      this.$forceUpdate()
    },
    submit() {
      const data = {
        slug: this.item.slug,
        position: this.item.position,
        avatar: this.item.avatar,
        title: this.item.title,
        description: this.item.description,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        extensions: _.map(this.item.extensions, ext => ext.value),
      }
      if (!this.valueId) {
        this.$http.post(`/api/attributes/${this.attributeId}/values/`, data)
          .then(() => {
            this.$store.dispatch('validation/clearErrors')
            this.$emit('refetch-data')
            this.$emit('update:opened-value-form', false)
            this.item = null
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      } else {
        this.$http.put(`/api/attributes/${this.attributeId}/values/${this.valueId}`, data)
          .then(() => {
            this.$store.dispatch('validation/clearErrors')
            this.$emit('refetch-data')
            this.$emit('update:opened-value-form', false)
            this.item = null
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }
    },
    async searchExtension(query) {
      await this.$http.get('/api/admin/extensions', {
        params: {
          query,
          per_page: 500,
        },
      })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    addExtension() {
      this.item.extensions.push(_.find(this.extensionOptions, { value: this.addExtensionId }))
      this.addExtensionId = null
    },
    removeExtension(index) {
      this.item.extensions.splice(index, 1)
    },
    transformData(item) {
      let data = {}
      if (item) {
        data = {
          slug: item.slug,
          position: item.position,
          avatar: this.$options.filters.singleMedia(item.avatar),
          title: {},
          description: {},
          meta_description: {},
          meta_h1: {},
          meta_keywords: {},
          meta_title: {},
          canonical: {},
          extensions: this.$options.filters.transformForVSelect(item.extensions, 'id', 'title'),
        }
        _.each(this.languages, language => {
          const translation = _.find(item.translations, { locale: language.code })
          data.title[language.code] = translation ? translation.title : null
          data.description[language.code] = translation ? translation.description : null
          data.meta_description[language.code] = translation ? translation.meta_description : null
          data.meta_h1[language.code] = translation ? translation.meta_h1 : null
          data.meta_keywords[language.code] = translation ? translation.meta_keywords : null
          data.meta_title[language.code] = translation ? translation.meta_title : null
          data.canonical[language.code] = translation ? translation.canonical : null
        })
      } else {
        data = {
          slug: null,
          avatar: null,
          title: {},
          description: {},
          meta_description: {},
          meta_h1: {},
          meta_keywords: {},
          meta_title: {},
          canonical: {},
          extensions: [],
        }
        _.each(this.languages, language => {
          data.title[language.code] = null
          data.description[language.code] = null
          data.meta_description[language.code] = null
          data.meta_h1[language.code] = null
          data.meta_keywords[language.code] = null
          data.meta_title[language.code] = null
          data.canonical[language.code] = null
        })
      }
      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-value-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
